<!--
 * @Author: nice
 * @Date: 2021-11-12 22:49:07
 * @LastEditTime: 2021-12-07 09:56:12
 * @LastEditors: nice
 * @Description: 账户预存
-->
<template>
  <section class="stored-wrap">
    <NavBar :title="selfType === 'meter' ? '充值上表' : '账户预存'" background="blue"/>
    <van-notice-bar v-show="!platformStatus.enable" scrollable :text="this.platformStatus.msg" background="opacity"
                    left-icon="volume-o"/>
    <div class="info-card bBlue">
      <div class="name-wrap card">
        <!--        v-if="selfType === 'meter'"-->
        <template>
          <p>
            <span>姓名</span>
            <span>{{ accountData.customerName }}</span>
          </p>
          <p>
            <span>电话</span>
            <span>{{ accountData.phone }}</span>
          </p>
          <p>
            <span>用户编号</span>
            <span>{{ customerNumber }}</span>
          </p>
          <!--          <p>-->
          <!--            <span>表号</span>-->
          <!--            <span>{{ deviceNumber }}</span>-->
          <!--          </p>-->
          <!--          <p>-->
          <!--            <span>地址</span>-->
          <!--            <span>{{ fullAddress }}</span>-->
          <!--          </p>-->
        </template>
        <!--        <template v-else>-->
        <!--          <p>-->
        <!--            <span>姓名</span>-->
        <!--            <span>{{ accountData.customerName }}</span>-->
        <!--          </p>-->
        <!--          <p>-->
        <!--            <span>身份证号</span>-->
        <!--            <span>{{ accountData.idCard }}</span>-->
        <!--          </p>-->
        <!--          <p>-->
        <!--            <span>电话</span>-->
        <!--            <span>{{ accountData.phone }}</span>-->
        <!--          </p>-->
        <!--        </template>-->
      </div>
      <div class="price-wrap card marginT10">
        <p class="blue price-item" v-for="(item, index) in priceVal" :key="index" @click="() => selectPrice(item)">
          <b>{{ item }}</b>
          <span>元</span>
        </p>
      </div>
      <div class="search-wrap marginT10">
        <van-field
          v-model="value"
          type="digit"
          inputmode="numeric"
          :maxlength="10000"
          label="自定义金额"
          placeholder=" 请输入10-10000的整数"
          input-align="left"
        />
      </div>
    </div>
    <div class="btn-wrap-x">
      <van-button v-show="platformStatus.enable" type="info" :disabled="!this.value || this.isOver" class="self-btn"
                  color="#4284f3" @click="pay">充值
      </van-button>

    </div>
  </section>
</template>

<script>
import {getCustomerNumber4AppVo, createRechargeOrder, getPlatformStatus} from '@/services/payServer.js';
import {goToPayUrlWithWxCode} from '@/services/httpUrlConfig.js';
import {Toast, Notify} from 'vant';

export default {
  data() {
    const {keyword, selfType, customerNumber, deviceNumber, fullAddress, customerNumberId} = this.$route.query;
    return {
      value: '',
      selfType,
      userValue: keyword,
      accountData: {},
      customerNumber,
      deviceNumber,
      fullAddress,
      customerNumberId,
      priceVal: [20, 50, 100, 200, 300, 500],
      isOver: false,
      platformStatus: {
        enable: true, // 默认开启
        msg: '', // 当平台停用时的消息提示
      }, // 平台启用状态
    };
  },
  created() {
    this.hideshare();
    // 充值上表不需要用户信息
    this.getAccountInfo();
    // if (this.selfType !== 'meter') {
    //   this.getAccountInfo();
    // }
    this.loadPlatformStatus()
  },
  methods: {
    onBridgeReady() {
      WeixinJSBridge.call('hideOptionMenu');
    },
    hideshare() {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
        }
      } else {
        this.onBridgeReady();
      }
    },
    // 获取用户信息
    getAccountInfo() {
      getCustomerNumber4AppVo({queryParam: this.userValue})
        .then(res => {
          if (+res.code === 9200) {
            this.accountData = res.data || {};
          } else {
            Toast.fail(res.msg);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    selectPrice(price) {
      this.value = price;
    },
    pay() {
      const {customerName, idCard, customerAccountId} = this.accountData;
      this.isOver = true;
      /**
       * 填入用户号id=表端充值
       *  填入账户id=账户充值
       * 两个id不能同时为空或同时填写, 每次请求必须一个有值一个为空
       */
      if (this.value < 10 || this.value > 10000) {
        Toast('请输入10-10000的整数');
        this.isOver = false;
        return;
      }
      // 充值按钮触发后校验结账时间
      getPlatformStatus().then(res => {
        let platform = this.util.getTranscateRst(res);
        this.platformStatus.enable = platform.state
        this.platformStatus.msg = platform.msg
        // 允许访问
        if (platform.state) {
          createRechargeOrder({
            rechargeCount: this.value,
            // accountId: this.accountData?.customerAccountId, // 充值账号Id
            customerNumberId: this.customerNumberId // 用户号id
          }).then(res => {
            if (+res.code === 9200) {
              // 充值上表
              const parames = {
                orderId: res.data,
                amount: this.value,
                customerNumber: this.customerNumber,
              };
              goToPayUrlWithWxCode(parames);
              // if (this.selfType === 'meter') {
              //   // 充值上表
              //   const parames = {
              //     orderId: res.data,
              //     amount: this.value,
              //     customerNumber: this.customerNumber,
              //   };
              //   goToPayUrlWithWxCode(parames);
              //   return;
              // }
              // // 账户预存
              // if (this.selfType === 'stored') {
              //   const parames = {
              //     orderId: res.data,
              //     amount: this.value,
              //     accountId: this.userValue,
              //   };
              //   goToPayUrlWithWxCode(parames);
              // }
            } else {
              this.isOver = false;
              Toast.fail(res.msg);
            }
          }).catch(res => {
            this.isOver = false;
          });
        } else {
          Notify({
            type: 'danger',
            message: this.platformStatus.msg,
          });
        }
      })
    },
    /**
     * 微信公众号是否可访问
     */
    loadPlatformStatus: function () {
      getPlatformStatus().then(res => {
        let platform = this.util.getTranscateRst(res);
        this.platformStatus.enable = platform.state
        this.platformStatus.msg = platform.msg
      })
    }
  }
};
</script>

<style lang="less">
.stored-wrap {
  .btn-wrap-x {
    margin-top: 40px;
    padding: 0 13px;

    .self-btn {
      width: 100%;
    }
  }

  .info-card {
    padding: 13px;

    .card {
      background-color: #fff;
      color: #333;
      padding: 13px;
      border-radius: 4px;
    }

    .name-wrap {
      p {
        display: flex;
        justify-content: space-between;

        &:not(:first-child) {
          margin-top: 5px;
        }
      }
    }

    .price-wrap {
      background: rgba(255, 255, 255, 0.8);
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 10px;
      grid-column-gap: 10px;

      p {
        background: #fff;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:active {
          background-color: rgba(0, 0, 0, 0.2);
        }

        b {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
